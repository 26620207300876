
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../career-desire-logo.png";
import "../Styles/Navbar.css";
import { FaBars } from "react-icons/fa6";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <div className="container">
        <nav>
          <div className="main-header">
            <div className="header-logo">
              <img src={Logo} alt="#" />
            </div>
            <div className="menus">
              <ul className={isOpen ? "nav-link active" : "nav-link"}>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="Resume">Resume</Link>
                </li>
                <li>
                  <Link to="Services">Services</Link>
                </li>
                <li>
                  <Link to="Pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="CareerCoaching">Career Coaching</Link>
                </li>
              </ul>
            </div>
            <div className="header-btn">
              <button>
                <a href="">Login / Signup</a>
              </button>
            </div>
            <div className="mobile-icon" onClick={toggleMenu}>
              <FaBars />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
