import React from 'react'
import "../Styles/Banner.css"
import BanImg from "../Assets/banner-image.png"
import { FaPlus } from "react-icons/fa";
import cvIcon from "../Assets/cv-icon.png"
import bagIcon from "../Assets/bag.png"
import growth from "../Assets/graph-icon.png"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

const Banner = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: "ease",
        });
      }, []);

      
  return (
    <div className="banner-section">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="banner-content">
                <h6 data-aos="fade-right">Get Hired by Top Companies</h6>
                <h2 data-aos="fade-right" data-aos-delay="500">Craft Your Perfect <span>Resume</span> with Ease</h2>
                <p data-aos="fade-right" data-aos-delay="1000">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iusto eum error distinctio provident impedit asperiores eveniet eos in molestiae corrupti.</p>
                </div>
                <div className="banner-btn"  data-aos="fade-right" data-aos-delay="1500">
                    <button className='btn-1'><a href="">Contact us</a></button>
                    <button className='btn-2'><a href="">Check ATS Score</a></button>  
                </div>
            </div>
            <div className="col-lg-6">
                <div className="banner-right-content">
                    <div className="main-image" >
                        <img src={BanImg} alt="" data-aos="zoom-in" data-aos-delay="500"/>
                    </div>
                    <div className="bnr-bg-circle">
                        <span></span>
                    </div>
                    <div className="bnr-bg-icons">
                        <div className='icon-plus1 bounce'>
                            <FaPlus />
                        </div>
                        <div className='icon-plus2 bounce2'>
                        <FaPlus />
                        </div>
                        <div className='icon-plus3 bounce2'>
                        <FaPlus />
                        </div>
                        <div className='icon-ring1 bounce2'></div>
                        <div className='icon-ring2 bounce'></div>
                        <div className='icon-ring3 bounce2'></div>
                        <div className='bnr-block-1 bounce'>
                            <div className="bnr-block-1-content">
                                <div className="media">
                                    <img src={cvIcon} alt="" />
                                </div>
                                <h3 className='title'>Upload CV</h3>
                            </div>
                        </div>
                        <div className='bnr-block-2 bounce2'>
                            <div className="bnr-block-2-content">
                                <div className="bnr-block-2-bag">
                                    <span>
                                        <img src={bagIcon} alt="" />
                                    </span>
                                </div>
                                <div className="bnr-block-2-content-top">
                                    <h3 className='title'>
                                        25 K
                                    </h3>
                                    <div className="media">
                                        <img src={growth} alt="" />
                                    </div>
                                </div>
                                <div className="bnr-block-2-content-bottom">
                                People Got Hired
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Banner