import React from 'react'
import resume2 from "../Assets/resume2.png"
import '../Styles/Checkout.css'

const Checkout = () => {
    return (
        <div className="checkout_page">
            <div className="container">
                <div className="row">
                    <div className="main-title" data-aos="fade-down">
                        <h1>Checkout Form</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate provident magni, libero fugit expedita quos.</p>
                    </div>

                    <div className="col-lg-7">
                        <form action="">
                            <label>Name:
                                <input type="text" name="message" placeholder="Enter Your Name" />
                            </label>
                            <label>Contact Number:
                                <input type="text" name="message" placeholder="Enter Contact Number" />
                            </label>
                            <label>Email Address:
                                <input type="text" name="message" placeholder="Enter Email ID" />
                            </label>
                            <label htmlFor=""> Preferred time to Contact
                                <select name="" id="">
                                    <option value="">10 am - 11 am</option>
                                    <option value="">11 am - 12 pm</option>
                                    <option value="">1 pm - 2 pm</option>
                                    <option value="">2 pm - 3 pm</option>
                                    <option value="">3 pm - 4 pm</option>
                                    <option value="">4 pm - 5 pm</option>
                                    <option value="">5 pm - 6 pm</option>
                                    <option value="">6 pm - 7 pm</option>
                                    <option value="">7 pm - 8 pm</option>
                                </select>
                            </label>
                            <label htmlFor=""> Targetter Job Position :
                                <select name="" id="">
                                    <option value="">10 am - 11 am</option>
                                    <option value="">11 am - 12 pm</option>
                                    <option value="">1 pm - 2 pm</option>
                                    <option value="">2 pm - 3 pm</option>
                                    <option value="">3 pm - 4 pm</option>
                                    <option value="">4 pm - 5 pm</option>
                                    <option value="">5 pm - 6 pm</option>
                                    <option value="">6 pm - 7 pm</option>
                                    <option value="">7 pm - 8 pm</option>
                                </select>
                            </label>
                            <label htmlFor="">Upload Your Existing Resume
                                <input type="file" />
                            </label>
                            <div className="checkout-btn">
                                <button type='submit'>Place Order</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-5">
                        <div className="product-details">
                            <div className="head">
                                <h3>Item 1</h3>
                                <h5>Edit</h5>
                            </div>
                            <div className="item">
                                <div className="image">
                                    <img src={resume2} alt="" />
                                </div>
                                <div className="content">
                                    <h2>ATS Resume</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, nisi!</p>
                                    <span>Qty : 1</span>
                                </div>
                            </div>
                            <div className="price">
                                <div className="sub">
                                    <h6>Subtotal</h6>
                                    <h6>Rs. 599/-</h6>
                                </div>
                                <div className="final">
                                    <h4>Total to Pay</h4>
                                    <h4>Rs. 599/-</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout