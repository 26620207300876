import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import resume1 from "../Assets/resume1.png"
import resume2 from "../Assets/resume2.png"
import resume3 from "../Assets/resume3.png"
import resume4 from "../Assets/resume4.png"
import writterIcon from "../Assets/writter-icon.png"
import atsIcon from "../Assets/ats-icon.png"
import customisedIcon from "../Assets/customise-icon.png"
import yearIcon from "../Assets/1year-icon.png"
import "../Styles/Templates.css"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';



const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items:1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };



const Templates = () => {

    useEffect(() => {
        AOS.init({
          duration: 1500,
          easing: "ease",
        });
      }, []);


  return (
    <div className="templates-section">
        <div className="container">
            <div className="row">
            <div className="sec-title" data-aos="fade-down" data-aos-delay="200">
                        <h5>Top Companies</h5>
                        <h2>Select a Template that fits your Needs</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, expedita!</p>
                    </div>
                <div className="col-lg-4" data-aos="fade-right" data-aos-delay="200">
                    <Carousel
        className='resume-slider'
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={2000}
        infinite={true}
        arrows={false}
      >
        <div>
            <img src={resume1} alt="Company 1" />
        </div>
        <div>
            <img src={resume2} alt="Company 2" />
        </div>
        <div>
            <img src={resume3} alt="Company 3" />
        </div>
        <div>
            <img src={resume4} alt="Company 4" />
        </div>
      </Carousel>
                 <div className="template-btn">
                    <button className='btn-1'>Browse All Templates</button>  
                </div>
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="features-box"  data-aos="zoom-in" data-aos-delay="200">
                                <div className="box-content box-1">
                                    <div className="icon">
                                        <img src={writterIcon} alt="" />
                                    </div>
                                    <div className="title">
                                        <h4>Resume Written by Certified Writters</h4>
                                    </div>
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="features-box" data-aos="zoom-in"  data-aos-delay="200">
                                <div className="box-content box-2">
                                    <div className="icon">
                                        <img src={customisedIcon} alt="" />
                                    </div>
                                    <div className="title">
                                        <h4>Customised <br></br>Design </h4>
                                    </div>
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="features-box" data-aos="zoom-in"  data-aos-delay="200">
                                <div className="box-content box-3">
                                    <div className="icon">
                                        <img src={atsIcon} alt="" />
                                    </div>
                                    <div className="title">
                                        <h4>ATS Friendly Templates</h4>
                                    </div>
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="features-box" data-aos="zoom-in"  data-aos-delay="200">
                                <div className="box-content box-4">
                                    <div className="icon">
                                        <img src={yearIcon} alt="" />
                                    </div>
                                    <div className="title">
                                        <h4>1 Year Free Modifications</h4>
                                    </div>
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Templates