import React from 'react';
import '../Styles/OurClients.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import company1 from "../Assets/company1.png"
import company2 from "../Assets/company2.png"
import company3 from "../Assets/company3.png"
import company4 from "../Assets/company4.png"
import company5 from "../Assets/company5.png"
import company6 from "../Assets/company6.png"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Ourcompanys = () => {

  useEffect(() => {
    AOS.init({
      duration: 1300,
      easing: "ease",
    });
  }, []);


  return (
    <div className='company-section'>
      <div className="sec-title" data-aos="fade-down">
        <h5>Top Companies</h5>
        <h2>Our companys Hired By</h2>
      </div>
      <Carousel
        className='company-slider'
        responsive={responsive}
        autoPlay={true}
        arrows={false}
        autoPlaySpeed={2000}
        infinite={true}
      >
        <div data-aos="zoom-in">
          <img src={company1} alt="Company 1" />
        </div>
        <div data-aos="zoom-in">
          <img src={company2} alt="Company 2" />
        </div>
        <div data-aos="zoom-in">
          <img src={company3} alt="Company 3" />
        </div>
        <div data-aos="zoom-in">
          <img src={company4} alt="Company 4" />
        </div>
        <div data-aos="zoom-in">
          <img src={company5} alt="Company 5" />
        </div>
        <div data-aos="zoom-in">
          <img src={company6} alt="Company 6" />
        </div>
      </Carousel>
    </div>
  );
};

export default Ourcompanys;