import React from 'react'
import Banner from '../Components/Banner';
import OurClients from '../Components/OurClients';
import Templates from '../Components/Templates';
import Cta from '../Components/Cta';
import Process from '../Components/Process';
import ResumeTailored from '../Components/ResumeTailored';
import Ats from '../Components/Ats';
import WhyChoose from '../Components/WhyChoose';
import Testimonial from '../Components/Testimonial';

const Home = () => {
  return (
    <div>
        <Banner/>
        <OurClients/>
        <Templates/>
        <Cta/>
        <Process/>
        <ResumeTailored/>
        <Ats/>
        <WhyChoose/>
        <Testimonial/>
    </div>
  )
}

export default Home