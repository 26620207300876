import React from 'react'
import "../Styles/Ats.css"
import AtsImage from "../Assets/ats-image.svg"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';


const Ats = () => {
    useEffect(() => {
        AOS.init({
          duration: 1500,
          easing: "ease",
        });
      }, []);
  return (
    <div className="ats-section">
    <div className="container-fluid">
        <div className="row">       
            <div className="col-lg-6" data-aos="fade-right" data-aos-delay="200">
                <div className="animation">
                    {/* <iframe src="https://lottie.host/embed/30026e36-9881-425e-bd9a-44c4090033e8/D130VlFLkh.json"></iframe> */}
                    {/* <iframe src="https://lottie.host/embed/ab7d73b9-c074-43c5-9e09-cc2a8f70354a/Y2vjjOASjm.json"></iframe> */}
                    <iframe src="https://lottie.host/embed/787b3d8d-85ab-47f6-83dd-c6c6d0e74f3f/18CNkBhUhy.json"></iframe>
                </div>
            {/* <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script> 

            <dotlottie-player src="https://lottie.host/4091c3fc-b08f-4e48-8078-7c1f87b52f27/DX8ffrFQKR.json" background="transparent" speed="1" style={{width: "300px", height: "300px"}} loop autoplay></dotlottie-player> */}
            </div>
            <div className="col-lg-6 right-side" data-aos="fade-left" data-aos-delay="200">
            <div className="sec-title">
            <h5>Career Desire</h5>
            <h2>ATS Compatible Resume & Cover Letter</h2>
         </div>
         <div className="content">
         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit quos placeat ab culpa omnis error eligendi alias cumque atque exercitationem.</p>
         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, quis debitis. Laboriosam expedita possimus vitae modi tenetur quibusdam, velit cum enim quasi, in quo minus, tempore facilis doloribus consequuntur perferendis.</p>
         <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad voluptate enim ex nobis praesentium sed?</p>
         <div className="resumetailored-btn">
                <button>Contact Now</button>  
            </div>
         </div>
            </div>  
        </div>
    </div>
</div>
  )
}

export default Ats