import React from 'react'
import WhyChooseImg from '../Assets/why-choose-girl.png'
import WhyChooseImg2 from "../Assets/why-choose-img.png"
import Line from "../Assets/why-choose-img-line.png"
import "../Styles/WhyChoose.css"
import { FaChevronCircleRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';


const WhyChoose = () => {
    useEffect(() => {
        AOS.init({
            duration: 1500,
            easing: "ease",
        });
    }, []);
    return (
        <div className="why-choose-section ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 left-content" data-aos="fade-right" data-aos-delay="200">
                        <div className="sec-title">
                            <h5>Career Desire</h5>
                            <h2>Why Choose <span>Career Desire ?</span></h2>
                        </div>
                        <div className="content">
                            <ul>
                                <li>
                                    <FaChevronCircleRight className='icon' />
                                    <p>Resume Reviewd by Experts</p>
                                </li>
                                <li>
                                    <FaChevronCircleRight className='icon' />
                                    <p>Content Written by Certified Professionals</p>
                                </li>
                                <li>
                                    <FaChevronCircleRight className='icon' />
                                    <p>Unlimited Modifications Till Final Draft</p>
                                </li>
                                <li>
                                    <FaChevronCircleRight className='icon' />
                                    <p>Timely Delivery</p>
                                </li>
                                <li>
                                    <FaChevronCircleRight className='icon' />
                                    <p>Custom Design that Suits your Personality</p>
                                </li>
                                <li>
                                    <FaChevronCircleRight className='icon' />
                                    <p>One Stop Solution for all your Job Hunt & Personal Branding Needs</p>
                                </li>
                                <li>
                                    <FaChevronCircleRight className='icon' />
                                    <p>Get Exclusive Guidance from International Certified Career Coaches</p>
                                </li>
                            </ul>
                            <div className="resumetailored-btn">
                                <button>Contact Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-left" data-aos-delay="200">
                        <div className="right-content">
                            <div className="image">
                                <img src={WhyChooseImg2} alt="" />
                            </div>
                            <div className="shape">
                                <img src={Line} alt="" />
                            </div>
                            {/* <div className="pulse-animation">
                                <div className="pulse">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WhyChoose