import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import resume1 from "../Assets/resume1.png"
import resume2 from "../Assets/resume2.png"
import resume3 from "../Assets/resume3.png"
import resume4 from "../Assets/resume4.png"
import Shape1 from "../Assets/round-shape.png"
import Shape2 from "../Assets/plate-shape.png"
import "../Styles/BestTemplate.css"


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const BestTemplate = () => {

    useEffect(() => {
        AOS.init({
            duration: 1300,
            easing: "ease",
        });
    }, []);


    return (

        <>
            <div className="breadcrumb-new">
                <div className="container">
                    <div className="bread-title">
                        <h1>Resume Templates</h1>
                    </div>
                    <div className="navigation">
                        <h6>Home / Resume Template</h6>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente amet pariatur illum soluta eum placeat, quisquam laudantium velit, aut a animi sed voluptatem enim eius .</p>
                    <div className="breadcrumb-text">
                        <span>TEMPLATES</span>
                    </div>
                </div>
                <div className="shapes">
                    <div className="shape1">
                        <img src={Shape1} alt="" />
                    </div>
                    <div className="shape2">
                        <img src={Shape2} alt="" />
                    </div>
                </div>
            </div>


            <div className="template-sectionss">
                <div className='best-template'>
                    <div className="sec-title" data-aos="fade-down">
                        <h5>Resume Template</h5>
                        <h2>Choose the Best Template that Suit's Your Profile</h2>
                    </div>

                    <Carousel
                        className='resume-slider'
                        responsive={responsive}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        infinite={true}
                    >
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume1} alt="Company 1" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in">
                            <div className="image">
                                <img src={resume2} alt="Company 2" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume3} alt="Company 3" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume4} alt="Company 4" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume1} alt="Company 1" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in">
                            <div className="image">
                                <img src={resume2} alt="Company 2" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className='best-template best-template-2'>
                    <div className="sec-title" data-aos="fade-down">
                        <h5>Resume Template</h5>
                        <h2>Resume Templates By Profession</h2>
                    </div>
                    <Carousel
                        className='resume-slider'
                        responsive={responsive}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        infinite={true}
                    >
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume1} alt="Company 1" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in">
                            <div className="image">
                                <img src={resume2} alt="Company 2" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume3} alt="Company 3" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume4} alt="Company 4" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume1} alt="Company 1" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in">
                            <div className="image">
                                <img src={resume2} alt="Company 2" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className='best-template simple-ats best-template-3'>
                    <div className="sec-title" data-aos="fade-down">
                        <h5>Resume Template</h5>
                        <h2>Simple ATS Compatible Resumes</h2>
                    </div>
                    <Carousel
                        className='resume-slider'
                        responsive={responsive}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        infinite={true}
                    >
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume1} alt="Company 1" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in">
                            <div className="image">
                                <img src={resume2} alt="Company 2" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume3} alt="Company 3" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume4} alt="Company 4" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in" >
                            <div className="image">
                                <img src={resume1} alt="Company 1" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                        <div data-aos="zoom-in">
                            <div className="image">
                                <img src={resume2} alt="Company 2" />
                            </div>
                            <div className="best-template-btn">
                                <button>Choose this Template</button>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>

        </>


    );
};


export default BestTemplate