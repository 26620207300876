import React from 'react'
import "../Styles/Cta.css"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import Logo from "../career-desire-logo.png";

const Cta = () => {

    useEffect(() => {
        AOS.init({
            duration: 1500,
            easing: "ease",
        });
    }, []);


    return (
        <div className="container">
            <div className="cta-section" data-aos="fade-down" data-aos-delay="200">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="cta-content">
                            <h2>Request a Free Resume Review</h2>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore nostrum accusamus eius aspernatur vero.</p>
                        </div>
                        <div className="cta-btn">
                            <button>Talk to Our Experts</button>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="logo">
                            <img src={Logo} alt="" />
                        </div>
                        <div className="pulse-animation">
                            <div className="pulse">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Cta