import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaQuoteLeft } from "react-icons/fa";
import TestiImg from '../Assets/testi.png'
import "../Styles/Testimonial.css"
import GoogleIcon from '../Assets/google-logo.png'
import { FaStar } from "react-icons/fa6";
import { FaStarHalf } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';


const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };



const Testimonial = () => {

    useEffect(() => {
        AOS.init({
          duration: 1500,
          easing: "ease",
        });
      }, []);


  return (
    <div className="testimonial-section">
        <div className="container">
            <div className="testi-content" data-aos="fade-down" data-aos-delay="200">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="left-content">
                        <div className="sec-title">
                            <h5>Testimonials</h5>
                            <h2>What Our <span>Clients</span> Say Career Desire !!</h2>
                        </div>
                        <div className="google-rating">
                        <div className="star-content sec-title">
                        <img src={GoogleIcon} alt="" />
                            <div className="star">
                                <span className='star-count'>4.8</span>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStarHalf />
                                <span className='review-count'>50 Google Reviews</span>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="right-content">
                            <div className="testimonial-slide">
                            <Carousel
                                className='testi-slider'
                                responsive={responsive}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                infinite={true}
                                arrows={false}
                            >
                                <div>
                                    <div className="testimonial-content">
                                        <div className="testi-top">
                                            <div className="icon">
                                                <FaQuoteLeft />
                                            </div>
                                        </div>
                                        <div className="testi-description">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique temporibus illum nulla inventore error, necessitatibus consequatur, deserunt excepturi quia ab minus ducimus porro praesentium laboriosam voluptas aspernatur dolorum. Mollitia, possimus!</p>
                                        </div>
                                        <div className="testi-details">
                                            <div className="testi-image">
                                                <img src={TestiImg} alt="" />
                                            </div>
                                            <div className="name">
                                                <h6>Karthik</h6>
                                                <p>Career Desire</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="testimonial-content">
                                        <div className="testi-top">
                                            <div className="icon">
                                                <FaQuoteLeft />
                                            </div>
                                        </div>
                                        <div className="testi-description">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique temporibus illum nulla inventore error, necessitatibus consequatur, deserunt excepturi quia ab minus ducimus porro praesentium laboriosam voluptas aspernatur dolorum. Mollitia, possimus!</p>
                                        </div>
                                        <div className="testi-details">
                                            <div className="testi-image">
                                                <img src={TestiImg} alt="" />
                                            </div>
                                            <div className="name">
                                                <h6>Karthik</h6>
                                                <p>Career Desire</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="testimonial-content">
                                        <div className="testi-top">
                                            <div className="icon">
                                                <FaQuoteLeft />
                                            </div>
                                        </div>
                                        <div className="testi-description">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique temporibus illum nulla inventore error, necessitatibus consequatur, deserunt excepturi quia ab minus ducimus porro praesentium laboriosam voluptas aspernatur dolorum. Mollitia, possimus!</p>
                                        </div>
                                        <div className="testi-details">
                                            <div className="testi-image">
                                                <img src={TestiImg} alt="" />
                                            </div>
                                            <div className="name">
                                                <h6>Karthik</h6>
                                                <p>Career Desire</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial-outline-text">
                    <span>Testimonial</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Testimonial