import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "../Styles/CareerCoaching.css"
import resume1 from "../Assets/resume1.png"
import resume2 from "../Assets/resume2.png"
import resume3 from "../Assets/resume3.png"
import resume4 from "../Assets/resume4.png"
import { FaCheck } from "react-icons/fa6";
import { FaCrown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { FaCartShopping } from "react-icons/fa6";
import image1 from "../Assets/career-assessment.jpg"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import TestiImage from "../Assets/testi.png"
import { FaStar } from "react-icons/fa6";
import { FaStarHalf } from "react-icons/fa6";
import { BsLinkedin } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";




const CareerCoaching = () => {

    useEffect(() => {
        AOS.init({
            duration: 1500,
            easing: "ease",
        });
    }, []);


    return (
        <>
            <div className="career-banner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="banner-content-all">
                                <div className="banner-content">
                                    <h1>Career Desire - The Perfect Career Awaits You !!!</h1>
                                </div>
                                <div className="mini-services">
                                    <h6>Career Assessment | Personalised Career Guidance | Profile Building | Virtual Interships | Career Roadmap Planning</h6>
                                </div>
                                <div className="banner-btns">
                                    <button>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">

                            {/* <div className="perspective-image-animation">
                                <div className="image image-animation-1">
                                    <img src={resume1} alt="" />
                                </div>
                                <div className="image image-animation-2">
                                    <img src={resume2} alt="" />
                                </div>
                                <div className="image image-animation-3">
                                    <img src={resume3} alt="" />
                                </div>
                                <div className="image image-animation-4">
                                    <img src={resume4} alt="" />
                                </div>
                            </div> */}


                            <div className="perspective-image-animation">
                                <div className="second-div">
                                    <div className="third-div">
                                        <div className='relative'>
                                            <img src={resume1} alt="" className='firstimage' />
                                            <img src={resume2} alt="" className='secondimage' />
                                        </div>
                                    </div>
                                    <div className="fourth-div">
                                        <div className="fifth-div">
                                            <img src={resume3} alt="" />
                                        </div>
                                        <div className="sixth-div">
                                            <div className="seven-div">
                                                <img src={resume4} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="career-assessment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="content">
                                <h2>Advanced Career Assessment</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos doloremque quo explicabo illo aliquid, placeat blanditiis dolore quidem porro deserunt voluptates, voluptatibus fuga architecto aliquam doloribus beatae vero. Reprehenderit, tempore reiciendis dolorem culpa temporibus neque ullam expedita aspernatur. Ipsum aspernatur deserunt id quod omnis hic doloribus, libero dolores eveniet ut.</p>
                                <button>Take Free Demo</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image">
                                <img src={image1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="career-assessment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image">
                                <img src={image1} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="content">
                                <h2>Advanced Career Assessment</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos doloremque quo explicabo illo aliquid, placeat blanditiis dolore quidem porro deserunt voluptates, voluptatibus fuga architecto aliquam doloribus beatae vero. Reprehenderit, tempore reiciendis dolorem culpa temporibus neque ullam expedita aspernatur. Ipsum aspernatur deserunt id quod omnis hic doloribus, libero dolores eveniet ut.</p>
                                <button>Take Free Demo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="career-assessment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="content">
                                <h2>Advanced Career Assessment</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos doloremque quo explicabo illo aliquid, placeat blanditiis dolore quidem porro deserunt voluptates, voluptatibus fuga architecto aliquam doloribus beatae vero. Reprehenderit, tempore reiciendis dolorem culpa temporibus neque ullam expedita aspernatur. Ipsum aspernatur deserunt id quod omnis hic doloribus, libero dolores eveniet ut.</p>
                                <button>Take Free Demo</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image">
                                <img src={image1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="career-assessment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image">
                                <img src={image1} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="content">
                                <h2>Advanced Career Assessment</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos doloremque quo explicabo illo aliquid, placeat blanditiis dolore quidem porro deserunt voluptates, voluptatibus fuga architecto aliquam doloribus beatae vero. Reprehenderit, tempore reiciendis dolorem culpa temporibus neque ullam expedita aspernatur. Ipsum aspernatur deserunt id quod omnis hic doloribus, libero dolores eveniet ut.</p>
                                <button>Take Free Demo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pricing-section career-coaching-pricing">
                <div className="container">
                    <div className="row">
                        <div className="main-title" data-aos="fade-down">
                            <h1>Pricing Packages</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, aliquam!</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="price-box">
                                <div className="price-head">
                                    <h3><span>BASIC PLAN</span></h3>
                                    <div className="amount">
                                        <h1>₹ 1999 <sup>33/m</sup></h1>
                                        <p>₹1990 billed every 3 months</p>
                                    </div>
                                </div>
                                <div className="price-content">
                                    <ul>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price-foot">
                                    <button>Build My Resume</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="price-box popular-box">
                                <div className="price-head">
                                    <div className="top-head">
                                        <h3><FaCrown className='icon' /><span>PROFESSIONAL PLAN</span></h3>
                                        <h4 className='discount'>Save 25%</h4>
                                    </div>
                                    <div className="amount">
                                        <h1>Rs. 999 <sup>33/m</sup></h1>
                                        <p>₹1990 billed every 3 months</p>
                                    </div>
                                </div>
                                <div className="price-content">
                                    <ul>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Career Coachin Session</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Career Roadmap Planning</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />ATS Resume</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Cover Letter / SOP</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Business Card Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Banner Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Training</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Other Social Profile Building</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Personal Website</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Interview Preparation</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price-foot">
                                    <button>Build My Resume</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="price-box premium-box">
                                <div className="price-head">
                                    <div className="top-head">
                                        <h3><FaCrown className='icon' /><span>PREMIUM PLAN</span></h3>
                                        <h4 className='discount'>Save 25%</h4>
                                    </div>
                                    <div className="amount">
                                        <h1>Rs. 999 <sup>33/m</sup></h1>
                                        <p>₹1990 billed every 3 months</p>
                                    </div>
                                </div>
                                <div className="price-content">
                                    <ul>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Career Coachin Session</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Career Roadmap Planning</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />ATS Resume</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Cover Letter / SOP</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Business Card Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Banner Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Training</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Other Social Profile Building</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Personal Website</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Interview Preparation</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price-foot">
                                    <button>Build My Resume</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="career-testimonial-section">
                <div className="container">
                    <div className="row">
                        <div className="main-title" data-aos="fade-down">
                            <h1>Clients Review's About Career Desire</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, aliquam!</p>
                        </div>
                        <div className="col-lg-4 testimonial-column1">
                            <div className="testi-item testi-1">
                                <div className="top-head">
                                    <div className="image left">
                                        <img src={TestiImage} alt="" />
                                    </div>
                                    <div className="right">
                                        <div className="star-rating">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                        </div>
                                        <h6>2 months ago</h6>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio natus cupiditate dicta, inventore soluta debitis aliquam ut iste mollitia quae?</p>
                                </div>
                                <div className="foot">
                                    <div className="name">
                                        <h6>Karthik</h6>
                                        <p>Designer at Tech Park</p>
                                    </div>
                                    <div className="social-icon">
                                        <BsLinkedin />
                                    </div>
                                </div>
                            </div>
                            <div className="testi-item testi-2">
                                <div className="top-head">
                                    <div className="image left">
                                        <img src={TestiImage} alt="" />
                                    </div>
                                    <div className="right">
                                        <div className="star-rating">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                        </div>
                                        <h6>2 months ago</h6>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio natus cupiditate dicta, inventore soluta debitis aliquam ut iste mollitia quae?</p>
                                </div>
                                <div className="foot">
                                    <div className="name">
                                        <h6>Karthik</h6>
                                        <p>Designer at Tech Park</p>
                                    </div>
                                    <div className="social-icon">
                                        <BsLinkedin />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 testimonial-column2">
                            <div className="testi-item testi-3 testi-glass">
                                <div className="top-head">
                                    <div className="right">
                                        <div className="star-rating">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStarHalf />
                                        </div>
                                        <h6><span>4.5</span> Rating</h6>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>2k Happy Customers Shared Their Experience</p>
                                </div>
                                <div className="foot">
                                    <div className="name">
                                        <h6>Read More Reviews</h6>
                                    </div>
                                    <div className="social-icon">
                                        <FcGoogle />
                                    </div>
                                </div>
                            </div>
                            <div className="testi-item testi-4">
                                <div className="top-head">
                                    <div className="image left">
                                        <img src={TestiImage} alt="" />
                                    </div>
                                    <div className="right">
                                        <div className="star-rating">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                        </div>
                                        <h6>2 months ago</h6>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illo, aut! Illum dolores.</p>
                                </div>
                                <div className="foot">
                                    <div className="name">
                                        <h6>Karthik</h6>
                                        <p>Designer at Tech Park</p>
                                    </div>
                                    <div className="social-icon">
                                        <BsLinkedin />
                                    </div>
                                </div>
                            </div>
                            <div className="testi-item testi-5">
                                <div className="top-head">
                                    <div className="image left">
                                        <img src={TestiImage} alt="" />
                                    </div>
                                    <div className="right">
                                        <div className="star-rating">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStarHalf />
                                        </div>
                                        <h6>2 months ago</h6>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illo, aut! Illum dolores.</p>
                                </div>
                                <div className="foot">
                                    <div className="name">
                                        <h6>Karthik</h6>
                                        <p>Designer at Tech Park</p>
                                    </div>
                                    <div className="social-icon">
                                        <BsLinkedin />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 testimonial-column3">
                            <div className="testi-item testi-6">
                                <div className="top-head">
                                    <div className="image left">
                                        <img src={TestiImage} alt="" />
                                    </div>
                                    <div className="right">
                                        <div className="star-rating">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStarHalf />
                                        </div>
                                        <h6>2 months ago</h6>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio natus cupiditate dicta, inventore soluta debitis aliquam ut iste mollitia quae?</p>
                                </div>
                                <div className="foot">
                                    <div className="name">
                                        <h6>Karthik</h6>
                                        <p>Designer at Tech Park</p>
                                    </div>
                                    <div className="social-icon">
                                        <BsLinkedin />
                                    </div>
                                </div>
                            </div>
                            <div className="testi-item testi-7">
                                <div className="top-head">
                                    <div className="image left">
                                        <img src={TestiImage} alt="" />
                                    </div>
                                    <div className="right">
                                        <div className="star-rating">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStarHalf />
                                        </div>
                                        <h6>2 months ago</h6>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio natus cupiditate dicta, inventore soluta debitis aliquam ut iste mollitia quae?</p>
                                </div>
                                <div className="foot">
                                    <div className="name">
                                        <h6>Karthik</h6>
                                        <p>Designer at Tech Park</p>
                                    </div>
                                    <div className="social-icon">
                                        <BsLinkedin />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerCoaching