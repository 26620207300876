import React from 'react'
import Service1 from '../Assets/ser-career-coaching.jpg'
import '../Styles/Services.css'
import resume1 from "../Assets/resume1.png"
import resume2 from "../Assets/resume2.png"
import resume3 from "../Assets/resume3.png"
import resume4 from "../Assets/resume4.png"
import resume5 from "../Assets/resume5.png"
import LinkedinImage from "../Assets/linkedin-service.png"
import website1 from '../Assets/website-mockup.png'
import website2 from '../Assets/portfolio-website.png'
import website3 from '../Assets/website-pnggg.png'
import interview from '../Assets/interview.jpg'
import AstractShape from "../Assets/abstract-shape.png"
import Shape1 from "../Assets/round-shape.png"
import Shape2 from "../Assets/plate-shape.png"
import Line from "../Assets/why-choose-img-line.png"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react'

const Services = () => {

    useEffect(() => {
        AOS.init({
            duration: 1300,
            easing: "ease",
        });
    }, []);

    return (

        <>
            <div className="breadcrumb-new">
                <div className="container">
                    <div className="bread-title">
                        <h1>Our Services</h1>
                    </div>
                    <div className="navigation">
                        <h6>Home / Our Services</h6>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente amet pariatur illum soluta eum placeat, quisquam laudantium velit, aut a animi sed voluptatem enim eius .</p>
                    <div className="breadcrumb-text">
                        <span>TEMPLATES</span>
                    </div>
                </div>
                <div className="shapes">
                    <div className="shape1">
                        <img src={Shape1} alt="" />
                    </div>
                    <div className="shape2">
                        <img src={Shape2} alt="" />
                    </div>
                </div>
            </div>


            <div className="service_page">
                <div className="container">
                    <div className="row item">
                        <div className="col-lg-6">
                            <div className="image">
                                <img src={Service1} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 service-content">
                            <div className="content">
                                <h1>Career Coaching</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa sunt odio dolore cum a minima, ipsam deserunt quisquam! Consectetur, explicabo unde possimus quod vero repellendus quibusdam voluptas perspiciatis eligendi in suscipit reprehenderit magni excepturi voluptatibus! Quas natus dolorum voluptatum itaque, sequi possimus aliquam dicta et alias eius hic voluptate nulla.</p>
                            </div>
                            <div className="btns">
                                <div className="best-template-btn">
                                    <button>Learn More</button>
                                </div>
                                <div className="best-template-btn">
                                    <button>Pricing</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row item ats-resume-section">
                        <div className="col-lg-6 service-content">
                            <div className="content">
                                <h1>ATS Resume</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa sunt odio dolore cum a minima, ipsam deserunt quisquam! Consectetur, explicabo unde possimus quod vero repellendus quibusdam voluptas perspiciatis eligendi in suscipit reprehenderit magni excepturi voluptatibus! Quas natus dolorum voluptatum itaque, sequi possimus aliquam dicta et alias eius hic voluptate nulla.</p>
                            </div>
                            <div className="btns">
                                <div className="best-template-btn">
                                    <button>Pricing</button>
                                </div>
                                <div className="best-template-btn">
                                    <button>Browse Templates</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 image-section">
                            <div className="shape">
                                <img src={AstractShape} alt="" />
                            </div>
                            <div className="image resume">
                                <img src={resume1} alt="" className='resume0' />
                                <img src={resume2} alt="" className='resume1' />
                                <img src={resume5} alt="" className='resume2' />
                                <img src={resume4} alt="" className='resume3' />
                                <img src={resume3} alt="" className='resume4' />
                            </div>
                            {/* <div class="resume-container">
                                <img src={resume1} alt="" className='resume-new' />
                                <img src={resume2} alt="" className='resume-new' />
                                <img src={resume3} alt="" className='resume-new' />
                                <img src={resume4} alt="" className='resume-new' />
                                <img src={resume1} alt="" className='resume-new' />
                            </div> */}

                        </div>
                    </div>
                    <div className="row item ats-resume-section">
                        <div className="col-lg-6 image-section">
                            <div className="shape">
                                <img src={AstractShape} alt="" />
                            </div>
                            <div className="image resume">
                                <img src={resume1} alt="" className='resume0' />
                                <img src={resume2} alt="" className='resume1' />
                                <img src={resume5} alt="" className='resume2' />
                                <img src={resume4} alt="" className='resume3' />
                                <img src={resume3} alt="" className='resume4' />
                            </div>
                            {/* <div class="resume-container">
                                <img src={resume1} alt="" className='resume-new' />
                                <img src={resume2} alt="" className='resume-new' />
                                <img src={resume3} alt="" className='resume-new' />
                                <img src={resume4} alt="" className='resume-new' />
                                <img src={resume1} alt="" className='resume-new' />
                            </div> */}

                        </div>
                        <div className="col-lg-6 service-content">
                            <div className="content">
                                <h1>Cover Letter</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa sunt odio dolore cum a minima, ipsam deserunt quisquam! Consectetur, explicabo unde possimus quod vero repellendus quibusdam voluptas perspiciatis eligendi in suscipit reprehenderit magni excepturi voluptatibus! Quas natus dolorum voluptatum itaque, sequi possimus aliquam dicta et alias eius hic voluptate nulla.</p>
                            </div>
                            <div className="btns">
                                <div className="best-template-btn">
                                    <button>Pricing</button>
                                </div>
                                <div className="best-template-btn">
                                    <button>Browse Templates</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row item">
                        <div className="col-lg-6 service-content">
                            <div className="content">
                                <h1>Personalised Website</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa sunt odio dolore cum a minima, ipsam deserunt quisquam! Consectetur, explicabo unde possimus quod vero repellendus quibusdam voluptas perspiciatis eligendi in suscipit reprehenderit magni excepturi voluptatibus! Quas natus dolorum voluptatum itaque, sequi possimus aliquam dicta et alias eius hic voluptate nulla.</p>
                            </div>
                            <div className="btns">
                                <div className="best-template-btn">
                                    <button>Learn More</button>
                                </div>
                                <div className="best-template-btn">
                                    <button>Pricing</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image animation">
                                <img src={website3} alt="" />
                            </div>
                        </div>
                    </div>




                    <div className="row item">
                        <div className="col-lg-6">
                            <div className="image animation linkedin-image">
                                {/* <iframe src="https://lottie.host/embed/8860bbd5-4d5d-4053-badc-3c83849076d9/p7FyJ5qQHC.json"></iframe> */}
                                <img src={LinkedinImage} alt="" style={{ width: "450px" }} />
                                <div className="shape">
                                    <img src={Line} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 service-content">
                            <div className="content">
                                <h1>Linkedin Optimization</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa sunt odio dolore cum a minima, ipsam deserunt quisquam! Consectetur, explicabo unde possimus quod vero repellendus quibusdam voluptas perspiciatis eligendi in suscipit reprehenderit magni excepturi voluptatibus! Quas natus dolorum voluptatum itaque, sequi possimus aliquam dicta et alias eius hic voluptate nulla.</p>
                            </div>
                            <div className="btns">
                                <div className="best-template-btn">
                                    <button>Learn More</button>
                                </div>
                                <div className="best-template-btn">
                                    <button>Pricing</button>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className="row item">
                        <div className="col-lg-6 service-content">
                            <div className="content">
                                <h1>Interview Preparation</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa sunt odio dolore cum a minima, ipsam deserunt quisquam! Consectetur, explicabo unde possimus quod vero repellendus quibusdam voluptas perspiciatis eligendi in suscipit reprehenderit magni excepturi voluptatibus! Quas natus dolorum voluptatum itaque, sequi possimus aliquam dicta et alias eius hic voluptate nulla.</p>
                            </div>
                            <div className="btns">
                                <div className="best-template-btn">
                                    <button>Learn More</button>
                                </div>
                                <div className="best-template-btn">
                                    <button>Pricing</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image animation">
                                <img src={interview} alt="" />
                            </div>
                        </div>
                    </div>







                </div>
            </div>

        </>
    )
}

export default Services