import React from 'react'
import Process1 from "../Assets/process1-icon.png"
import Process2 from "../Assets/process2-icon.png"
import Process3 from "../Assets/process3-icon.png"
import Process4 from "../Assets/process4-icon.png"
import Process5 from "../Assets/process5-icon.png"
import Process6 from "../Assets/process6-icon.png"
import "../Styles/Process.css"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

const Process = () => {

    useEffect(() => {
        AOS.init({
          duration: 1500,
          easing: "ease",
        });
      }, []);


  return (
     <div className="process-section">
        <div className="container">
            <div className="row">
            <div className="sec-title" data-aos="fade-down" >
                <h5>Work process</h5>
                <h2>How Resume Writing Process at Career Desire Works</h2>
             </div>
                <div className="col-lg-4">
                    <div className="process-box box-1" data-aos="zoom-in" >
                        <span className='process-number'>01</span>
                        <div className="box-content box1">
                        <div className="icon-box">
                            <img src={Process1} alt="" />
                        </div>
                        <div className="title">
                        <h4>Your Resume <br/>Preview</h4>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="process-box" data-aos="zoom-in" >
                        <span className='process-number'>02</span>
                        <div className="box-content box2">
                        <div className="icon-box">
                            <img src={Process2} alt="" />
                        </div>
                        <div className="title">
                        <h4>Client's Need <br/>Assessment</h4>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="process-box" data-aos="zoom-in" >
                        <span className='process-number'>03</span>
                        <div className="box-content box3">
                        <div className="icon-box">
                            <img src={Process3} alt="" />
                        </div>
                        <div className="title">
                        <h4>Resume <br/>Draft</h4>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="process-box" data-aos="zoom-in" >
                        <span className='process-number'>04</span>
                        <div className="box-content box4">
                        <div className="icon-box">
                            <img src={Process4} alt="" />
                        </div>
                        <div className="title">
                        <h4>Pick a<br/>Template</h4>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="process-box" data-aos="zoom-in" >
                        <span className='process-number'>05</span>
                        <div className="box-content box5">
                        <div className="icon-box">
                            <img src={Process5} alt="" />
                        </div>
                        <div className="title">
                        <h4>Finalise Draft & <br/>Format Resume</h4>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="process-box" data-aos="zoom-in" >
                        <span className='process-number'>06</span>
                        <div className="box-content box6">
                        <div className="icon-box">
                            <img src={Process6} alt="" />
                        </div>
                        <div className="title">
                        <h4>Order <br/>Complete</h4>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
  )
}

export default Process