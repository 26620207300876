import React from 'react'
import "../Styles/ResumeTailored.css"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import resume1 from '../Assets/resume1.png'
import resume2 from '../Assets/resume2.png'
import resume3 from '../Assets/resume3.png'
import resume4 from '../Assets/resume4.png'
import BlueShape from '../Assets/blue-shape.png'
import Arrow from "../Assets/arrow.png"

const ResumeTailored = () => {

    useEffect(() => {
        AOS.init({
            duration: 1500,
            easing: "ease",
        });
    }, []);


    return (
        <div className="resume-tailored-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 left-side" data-aos="fade-right" data-aos-delay="200">
                        <div className="sec-title" >
                            <h5>Career Desire</h5>
                            <h2>Resume Tailored Based on Targetted Job Position</h2>
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit quos placeat ab culpa omnis error eligendi alias cumque atque exercitationem.</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, quis debitis. Laboriosam expedita possimus vitae modi tenetur quibusdam, velit cum enim quasi, in quo minus, tempore facilis doloribus consequuntur perferendis.</p>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ad voluptate enim ex nobis praesentium sed?</p>
                            <div className="resumetailored-btn">
                                <button>Contact Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5" >
                        <div className="three-resume-animation">
                            <div className="resume1" data-aos="zoom-in" data-aos-delay="200">
                                <img src={resume1} alt="" />
                            </div>
                            <div className="resume2" data-aos="fade-left" data-aos-delay="1000">
                                <img src={resume2} alt="" />
                            </div>
                            <div className="resume3" data-aos="fade-right" data-aos-delay="1000">
                                <img src={resume3} alt="" />
                            </div>
                        </div>
                        <div className="shape">
                            <img src={Arrow} alt="" />
                        </div>
                        {/* <div className="animation">
                        <iframe src="https://lottie.host/embed/e26db212-bb2e-4736-a593-5c2ee3e7c929/77WScnDz9p.json"></iframe>
                    </div> */}
                        {/* <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script> 

                <dotlottie-player src="https://lottie.host/4091c3fc-b08f-4e48-8078-7c1f87b52f27/DX8ffrFQKR.json" background="transparent" speed="1" style={{width: "300px", height: "300px"}} loop autoplay></dotlottie-player> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResumeTailored