import React from 'react'
import '../Styles/Footer.css'
import Logo from "../career-desire-logo.png";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { IoSendSharp } from "react-icons/io5";



const currentYear = new Date().getFullYear();


const Footer = () => {
  return (
    <footer>
        <div className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 column1">
                        <div className="logo">
                            <img src={Logo} alt="#" />
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing consectetur adipisicing amet consectetur adipisicing adipisicing.</p>
                        <div className="social-media-icons">
                        <a href=""><RiInstagramFill /></a>
                        <a href=""><FaFacebookF /></a>
                        <a href=""><FaXTwitter /></a>
                        <a href=""><FaLinkedinIn /></a>
                        </div>
                    </div>
                    <div className="col-lg-2 column2">
                        <div className="footer-title">
                            <h4>Quick Links</h4>
                        </div>
                        <div className="list-items">
                            <ul>
                                <li>Home</li>
                                <li>Career Coaching</li>
                                <li>Services</li>
                                <li>Pricing</li>
                                <li>Contact us</li>
                                <li>Pricing</li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 column2">
                        <div className="footer-title">
                            <h4>Terms & Conditions</h4>
                        </div>
                        <div className="list-items">
                            <ul>
                                <li>Terms of Services</li>
                                <li>Privacy Policy</li>
                                <li>Refund Policy</li>
                                <li>Cookie Performances</li>
                                <li>Help</li>
                                <li>FAQ's</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 column2">
                        <div className="footer-title">
                            <h4>Contact Details</h4>
                        </div>
                        <div className="contact-details">
                            <ul>
                                <li>
                                    <span>Contact Number : </span>+91 12345 67890
                                </li>
                                <li>
                                    <span>Email ID : </span>careerdesire@gmail.com
                                </li>
                                <li>
                                    <span>Address : </span>Ambattur, Chennai, TamilNadu
                                </li>
                            </ul>
                            <div className="newsletter">
                                <h5>Subscribe to Our News Letter</h5>
                                <form action="">
                                    <div className="form-content">
                                    <input type="text" placeholder='Enter Your Mail ID'/>
                                    <div className="send">
                                        <IoSendSharp />
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="footer-bottom">
            <div className="container">
            <p>&copy; {currentYear} Career Desire. All rights reserved & Designed by <a href="https://techparkinfosolutions.com/">Tech Park Info Solutions</a></p>
            </div>
        </div>
    </footer>
  )
}

export default Footer