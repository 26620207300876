import './App.css';
import Navbar from './Components/Navbar'
import Footer from './Components/Footer';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Pages/Home'
import Resume from './Pages/Resume'
import CareerCoaching from './Pages/CareerCoaching';
import Services from './Pages/Services'
import Checkout from './Pages/Checkout';
import Pricing from './Pages/Pricing';

function App() {
  return (
    <div className="App">
      <Navbar/> 
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Resume" element={<Resume />} />
          <Route path="Services" element={<Services />} />
          <Route path="Checkout" element={<Checkout />} />
          <Route path="Pricing" element={<Pricing />} />
          <Route path="CareerCoaching" element={<CareerCoaching />} />
        </Routes>
      <Footer/> 
    </div>
  );
}

export default App;
