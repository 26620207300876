import React from 'react'
import "../Styles/Pricing.css"
import { GiCheckMark } from "react-icons/gi";
import { FaCheck } from "react-icons/fa6";
import { FaCrown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { FaCartShopping } from "react-icons/fa6";
import ShapeImage from "../Assets/3d-shape.png"
import Arrow from "../Assets/arrow-2.png"
import GelCircle1 from "../Assets/gel-circle.png"
import GelCircle2 from "../Assets/gel-circle-2.png"
import { Link } from 'react-router-dom';


const Pricing = () => {
    return (
        <>
            <div className="price-section">
                <div className="container">
                    <div className="row">
                        <div className="main-title" data-aos="fade-down">
                            <h1>Pricing Packages</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, aliquam!</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="price-box">
                                <div className="price-head">
                                    <h3><span>BASIC PLAN</span></h3>
                                    <div className="amount">
                                        <h1>₹ 1999 <sup>33/m</sup></h1>
                                        <p>₹1990 billed every 3 months</p>
                                    </div>
                                </div>
                                <div className="price-content">
                                    <ul>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price-foot">
                                    <button>Build My Resume</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="price-box popular-box">
                                <div className="price-head">
                                    <div className="top-head">
                                        <h3><FaCrown className='icon' /><span>PROFESSIONAL PLAN</span></h3>
                                        <h4 className='discount'>Save 25%</h4>
                                    </div>
                                    <div className="amount">
                                        <h1>Rs. 999 <sup>33/m</sup></h1>
                                        <p>₹1990 billed every 3 months</p>
                                    </div>
                                </div>
                                <div className="price-content">
                                    <ul>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Career Coachin Session</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Career Roadmap Planning</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />ATS Resume</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Cover Letter / SOP</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Business Card Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Banner Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Training</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Other Social Profile Building</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Personal Website</p>
                                        </li>
                                        <li>
                                            <p><RxCross2 className='icon wrong' />Interview Preparation</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price-foot">
                                    <button>Build My Resume</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="price-box premium-box">
                                <div className="price-head">
                                    <div className="top-head">
                                        <h3><FaCrown className='icon' /><span>PREMIUM PLAN</span></h3>
                                        <h4 className='discount'>Save 25%</h4>
                                    </div>
                                    <div className="amount">
                                        <h1>Rs. 999 <sup>33/m</sup></h1>
                                        <p>₹1990 billed every 3 months</p>
                                    </div>
                                </div>
                                <div className="price-content">
                                    <ul>
                                        <li>
                                            <p><FaCheck className='icon right' />Psyhometric Assessment</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Career Coachin Session</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Career Roadmap Planning</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />ATS Resume</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Cover Letter / SOP</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Business Card Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Banner Design</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Linkedin Training</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Other Social Profile Building</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Personal Website</p>
                                        </li>
                                        <li>
                                            <p><FaCheck className='icon right' />Interview Preparation</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="price-foot">
                                    <button>Build My Resume</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape">
                    <img src={ShapeImage} alt="" />
                </div>
                <div className="shape-2">
                    <img src={GelCircle1} alt="" />
                </div>

            </div>

            <div className="individual-service">
                <div className="container">
                    <div className="row">
                        <div className="main-title" data-aos="fade-down">
                            <h1>Individual Services</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, aliquam!</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-item">
                                <div className="service-title">
                                    <h2>Career Coaching</h2>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure, voluptatem?</p>
                                </div>
                                <div className="btn">
                                    <button>
                                        <Link to="../Checkout">Add to Cart<FaCartShopping className='cart-icon' /></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-item">
                                <div className="service-title">
                                    <h2>ATS Resume</h2>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure, voluptatem?</p>
                                </div>
                                <div className="btn">
                                    <button>
                                        <Link to="../Checkout">Add to Cart<FaCartShopping className='cart-icon' /></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-item">
                                <div className="service-title">
                                    <h2>Cover Letter Writing</h2>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure, voluptatem?</p>
                                </div>
                                <div className="btn">
                                    <button>
                                        <Link to="../Checkout">Add to Cart<FaCartShopping className='cart-icon' /></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-item">
                                <div className="service-title">
                                    <h2>Linkedin Optimisation</h2>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure, voluptatem?</p>
                                </div>
                                <div className="btn">
                                    <button>
                                        <Link to="../Checkout">Add to Cart<FaCartShopping className='cart-icon' /></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-item">
                                <div className="service-title">
                                    <h2>Personal Website</h2>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure, voluptatem?</p>
                                </div>
                                <div className="btn">
                                    <button>
                                        <Link to="../Checkout">Add to Cart<FaCartShopping className='cart-icon' /></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-item">
                                <div className="service-title">
                                    <h2>Personal Branding</h2>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure, voluptatem?</p>
                                </div>
                                <div className="btn">
                                    <button>
                                        <Link to="../Checkout">Add to Cart<FaCartShopping className='cart-icon' /></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Pricing